* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "human_sans_700";
  src: url('../src/assests/Fonts/human_sans/HumanSans_OTF/HumanSans-Regular.otf');
}

@font-face {
  font-family: "human_sans_400_bold";
  src: url('../src/assests/Fonts/human_sans/HumanSans_OTF/HumanSans-Regular.otf');
}

@font-face {
  font-family: "human_sans_400";
  src: url('../src/assests/Fonts/human_sans/HumanSans_OTF/HumanSans-Light.otf');
}

@font-face {
  font-family: "human_sans_700_bold";
  src: url('../src/assests/Fonts/human_sans/HumanSans_OTF/HumanSans-Bold.otf');
}

@font-face {
  font-family: "roboto_condensed_700";
  src: url('../src/assests/Fonts/roboto-condensed/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: "roboto_condensed_400";
  src: url('../src/assests/Fonts/roboto-condensed/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: "roboto_condensed";
  src: url('../src/assests/Fonts/roboto-condensed/RobotoCondensed-Regular.ttf');
}

.react-date-picker__calendar {
  z-index: 99 !important;

  .react-calendar {
    width: 100% !important;
  }
  }